// @flow
/**
 * Client
 * @module Client
 */
import apisauce from 'apisauce';
import axios from 'axios';
import config from 'config';
import { push } from 'modules/history';

const create = (baseURL = config.BASE_URL) => {
  const customAxiosInstance = axios.create({ baseURL });

  const api = apisauce.create({
    axiosInstance: customAxiosInstance,
  });

  api.addResponseTransform(response => {
    if (response.status === null || response.status === 500) {
      response.data = { errors: { message: 'Network error' } };
      response.status = 500;
      push('/internal-server-error');
    }

    if (response.status === 403) {
      response.data = { errors: { message: 'You are not authorized to access this resource' } };
      response.status = 403;
      push('/unauthorized-error');
    }
    if (response.status === 401) {
      response.data = { errors: { message: 'Incorrect token is provided, try re-login' } };
      response.status = 401;
      push('/expired-token');
    }
  });

  // api
  const setAuthorization = token => api.setHeader('jwt', token);
  const removeAuthToken = () => api.deleteHeader('jwt');

  // login
  const localLogin = data => api.post('/sign-in', data);

  const recoverPasswordRequest = data => api.post('/recover-password', { email: data });

  const resetPasswordRequest = data => api.post('/reset-password', data);

  const newPasswordRequest = data => api.post('/new-password', data);

  const resendInvitation = data => api.post('/resend-invitation', data);

  // profile
  const updateProfile = params => api.put('/users/me', params);

  const changePassword = params => api.put('/users/change-password', params);

  const closeAccount = () => api.delete('/users/me');

  // location
  const getLocations = params => api.get('/locations', params);

  const getLocation = id => api.get(`/locations/${id}`);

  const getLocationsForFilter = params => api.get('/locations/filter', params);

  const createLocation = data => api.post('/locations', data);

  const changeMembranes = data => api.post('/locations/changeMembrane', data);

  const getCsvFiles = params => api.get('/locations/csv-files', params);

  const setDeviceId = params => api.post('/locations/set-device-id', params);

  const editLocation = (id, data) => api.put(`/locations/${id}`, data);

  const updateInstallationStatus = (id, data) => api.put(`/locations/update-status/${id}`, data);

  const deleteLocation = id => api.delete(`/locations/${id}`);

  const getWaterFilterCurrentState = (serialNumber, data) =>
    api.get(`/wf/current-state/${serialNumber}`, data);

  const getWaterFilterCurrentPressure = (serialNumber, data) =>
    api.get(`/wf/current-pressure/${serialNumber}`, data);

  const getWaterFilterConsumption = params => api.get('/wf/consumption', params);

  const getWaterFilterMaxFlowRate = params => api.get('/wf/max-flow-rate', params);

  const getWaterFilterPressure = params => api.get('/wf/pressure', params);

  const getWaterFilterBypass = params => api.get('/wf/bypass', params);

  const getTotalConsumptionWaterFilter = params => api.get('/wf/total-consumption', params);

  const generateWaterFilterCsv = params => api.get('/wf/to-csv', params);

  const getWaterFilterCurrentBypass = (serialNumber, data) =>
    api.get(`/wf/current-bypass/${serialNumber}`, data);

  const getTotalConsumptionCartridgeWf = params => api.get('/wf/cartridge/consumption', params);

  const getAllTotalConsumptionCartridgeWf = params =>
    api.get('/wf/cartridge/all/consumption', params);

  const replaceWaterFilterCartridges = params => api.post('/wf/replace-cartridges', params);

  const getReverseOsmosisCurrentState = serialNumber =>
    api.get(`/ro/current-state/${serialNumber}`);

  const getReverseOsmosisConsumption = params => api.get('/ro/consumption', params);

  const getReverseOsmosisWaste = params => api.get('/ro/waste', params);

  const getReverseOsmosisTds = params => api.get('/ro/tds', params);

  const getTotalConsumptionReverseOsmosis = params => api.get('/ro/total-consumption', params);

  const generateReverseOsmosisCsv = params => api.get('/ro/to-csv', params);

  const getTotalConsumptionCartridgeRo = params => api.get('/ro/cartridge/consumption', params);

  const getAllTotalConsumptionCartridgeRo = params =>
    api.get('/ro/cartridge/all/consumption', params);

  const replaceReverseOsmosisCartridges = params => api.post('/ro/replace-cartridges', params);

  const getReverseOsmosisBypass = params => api.get('/ro/bypass', params);

  const getReverseOsmosisCurrentBypass = (serialNumber, params) =>
    api.get(`/ro/current-bypass/${serialNumber}`, params);

  const getReverseOsmosisCurrentPressure = (serialNumber, params) =>
    api.get(`/ro/current-pressure/${serialNumber}`, params);

  const getReverseOsmosisCurrentTemperature = (serialNumber, params) =>
    api.get(`/ro/current-temperature/${serialNumber}`, params);

  const getSoftenerCurrentState = (serialNumber, params) =>
    api.get(`/so/current-state/${serialNumber}`, params);

  const getSoftenerConsumption = params => api.get('/so/consumption', params);

  const getSoftenerPressure = params => api.get('/so/pressure', params);

  const getSoftenerBypass = params => api.get('/so/bypass', params);

  const getSoftenerWaste = params => api.get('/so/waste', params);

  const generateSoftenerCsv = params => api.get('/so/to-csv', params);

  const getTotalConsumptionSoftener = params => api.get('/so/total-consumption', params);

  const getSoftenerCurrentPressure = (serialNumber, data) =>
    api.get(`/so/current-pressure/${serialNumber}`, data);

  const getSoftenerCurrentBypass = (serialNumber, data) =>
    api.get(`/so/current-bypass/${serialNumber}`, data);

  // library
  const getLibraries = params => api.get('/libraries', params);

  const deleteLibrary = (id, type) => api.delete(`/libraries/${id}/${type}`);

  const getCatridge = id => api.get(`/libraries/${id}/catridge`);

  const getCatridgesForFilter = () => api.get('/libraries/filter/catridge');

  const getWaterFiltersForFilter = () => api.get('/libraries/filter/water-filter');

  const getConfigurationsForFilter = type => api.get(`/libraries/filter/${type}`);

  const getReverseOsmosisMembraneForFilter = () =>
    api.get('/libraries/filter/reverse-osmosis-membrane');

  const getReverseOsmosisForFilter = () => api.get('/libraries/filter/reverse-osmosis-filter');

  const getSoftenerForFilter = () => api.get('/libraries/filter/softener');

  const getBrineDrumForFilter = () => api.get('/libraries/filter/brine-drum');

  const getWaterFilter = id => api.get(`/libraries/${id}/water-filter`);

  const getReverseOsmosisMembrane = id => api.get(`/libraries/${id}/reverse-osmosis-membrane`);

  const getReverseOsmosis = id => api.get(`/libraries/${id}/reverse-osmosis-filter`);

  const getSoftener = id => api.get(`/libraries/${id}/softener`);

  const getBrineDrum = id => api.get(`/libraries/${id}/brine-drum`);

  const getTanks = () => api.get('/libraries/filter/tanks');

  const getConfiguration = (id, type) => api.get(`/libraries/${id}/${type}`);

  const createWaterFilter = data => api.post('/libraries/water-filter', data);

  const editWaterFilter = (id, data) => api.put(`/libraries/${id}/water-filter`, data);

  const createReverseOsmosisMembrane = data =>
    api.post('/libraries/reverse-osmosis-membrane', data);

  const createSensor = (data, sensor_type) => api.post(`/libraries/${sensor_type}-sensor`, data);

  const getSensor = (id, sensor_type) => api.get(`/libraries/${id}/${sensor_type}`);

  const editSensor = (data, id, sensor_type) => api.put(`/libraries/${id}/${sensor_type}`, data);

  const getFlowSensorsForSelect = () => api.get('/libraries/flow-meter-sensor-select');

  const getPressureSensorsForSelect = () => api.get('/libraries/pressure-transducer-sensor-select');

  const getDistanceSensorsForSelect = () => api.get('/libraries/distance-sensor-select');

  const getTdsProbeSensorsForSelect = () => api.get('/libraries/tds-probe-sensor-select');

  const getTemperatureSensorsForSelect = () => api.get('/libraries/temperature-sensor-select');

  const editReverseOsmosisMembrane = (id, data) =>
    api.put(`/libraries/${id}/reverse-osmosis-membrane`, data);

  const createWaterCatridge = data => api.post('/libraries/catridge', data);

  const editWaterCatridge = (id, data) => api.put(`/libraries/${id}/catridge`, data);

  const createReverseOsmosis = data => api.post('/libraries/reverse-osmosis-filter', data);

  const editReverseOsmosis = (id, data) => api.put(`/libraries/${id}/reverse-osmosis-filter`, data);

  const createBrineDrum = data => api.post('/libraries/brine-drum', data);

  const editBrineDrum = (id, data) => api.put(`/libraries/${id}/brine-drum`, data);

  const createSoftener = data => api.post('/libraries/softener', data);

  const editSoftener = (id, data) => api.put(`/libraries/${id}/softener`, data);

  const createDeviceConfiguration = (data, deviceType) =>
    api.post(`/libraries/${deviceType}-configuration`, data);

  const editDeviceConfiguration = (data, id, deviceType) =>
    api.put(`/libraries/${id}/${deviceType}`, data);

  // Alerts
  const getAlerts = params => api.get('/alerts', params);
  const getAllAlerts = params => api.get('/alerts/all', params);
  const getAlert = id => api.get(`/alerts/${id}`);

  const markAlertAsViewed = params => api.post('/alerts/mark-viewed', params);

  const getAlertsTypes = params => api.get('/alert-types', params);

  const getAlertType = id => api.get(`/alert-types/${id}`);

  const createAlertType = data => api.post('/alert-types', data);

  const editAlertType = (id, data) => api.put(`/alert-types/${id}`, data);

  const deleteAlertType = id => api.delete(`/alert-types/${id}`);

  const getUsers = params => api.get('/users', params);

  const getUser = id => api.get(`/users/${id}`);

  const resendEmail = id => api.post(`/users/resend-email/${id}`);

  const getInstallersByAccount = accountId => api.get(`users/${accountId}/installers`);

  const createUser = data => api.post('/users', data);

  const editUser = (id, data) => api.put(`/users/${id}`, data);

  const deleteUser = id => api.delete(`/users/${id}`);

  const getAccounts = params => api.get('/accounts', params);

  const getAccount = id => api.get(`/accounts/${id}`);

  const getAccountsForFilter = params => api.get('/accounts/filter', params);

  const createAccount = data => api.post('/accounts', data);

  const editAccount = (id, data) => api.put(`/accounts/${id}`, data);

  const deleteAccount = id => api.delete(`/accounts/${id}`);

  const getBrands = params => api.get('/brands', params);

  const getBrand = id => api.get(`/brands/${id}`);

  const getBrandsForFilter = params => api.get('/brands/filter', params);

  const createBrand = data => api.post('/brands', data);

  const editBrand = (id, data) => api.put(`/brands/${id}`, data);

  const deleteBrand = id => api.delete(`/brands/${id}`);

  // Ota
  const getOtas = params => api.get('/ota/locations-updates', params);

  const getOta = id => api.get(`/ota/${id}`);

  const getOtaVersions = params => api.get('/ota/manage-version', params);

  const createOtaVersion = data => api.post('/ota/manage-version', data);

  const deleteOtaVersion = id => api.delete(`/ota/manage-version/${id}`);

  const getUpgradeStatus = params => api.get('/ota/ota-updates', params);

  const getUpgradeStatusDevices = params => api.get('/ota/ota-updates/get-devices', params);

  const createUpgradeStatus = data => api.post('/ota/ota-updates', data);

  const deleteUpgradeStatus = id => api.delete(`/ota/ota-updates/${id}`);

  const getPreviousInstallationInfo = data => api.get('/ota/previous-installation-info', data);

  const getUpdateActiveDevices = params => api.get('/ota/manage-version/active-devices', params);

  // Roles, States, and Cities
  const getRoles = () => api.get('/roles');

  const getStates = () => api.get('/states');

  const getCitiesByState = id => api.get(`/states/${id}/cities`);

  // Support
  const getSupports = params => api.get('/supports', params);
  const deleteSupport = id => api.delete(`supports/${id}`);
  const checkAsRead = id => api.put(`/supports/${id}/check-as-read`);

  return {
    // api
    setAuthorization,
    removeAuthToken,
    // login
    localLogin,
    recoverPasswordRequest,
    resetPasswordRequest,
    newPasswordRequest,
    resendInvitation,
    // porfile
    updateProfile,
    changePassword,
    closeAccount,
    // library
    getLibraries,
    deleteLibrary,
    getCatridge,
    getCatridgesForFilter,
    getWaterFiltersForFilter,
    getConfigurationsForFilter,
    getReverseOsmosisMembraneForFilter,
    getReverseOsmosisForFilter,
    getSoftenerForFilter,
    getBrineDrumForFilter,
    getWaterFilter,
    getConfiguration,
    getReverseOsmosisMembrane,
    getReverseOsmosis,
    getSoftener,
    getBrineDrum,
    getTanks,
    createWaterFilter,
    editWaterFilter,
    createReverseOsmosisMembrane,
    createSensor,
    getSensor,
    editSensor,
    getFlowSensorsForSelect,
    getPressureSensorsForSelect,
    getDistanceSensorsForSelect,
    getTdsProbeSensorsForSelect,
    getTemperatureSensorsForSelect,
    editReverseOsmosisMembrane,
    createWaterCatridge,
    editWaterCatridge,
    createReverseOsmosis,
    editReverseOsmosis,
    createBrineDrum,
    editBrineDrum,
    createSoftener,
    editSoftener,
    createDeviceConfiguration,
    editDeviceConfiguration,
    // location
    getLocations,
    getLocation,
    getLocationsForFilter,
    createLocation,
    changeMembranes,
    getCsvFiles,
    setDeviceId,
    editLocation,
    deleteLocation,
    getWaterFilterCurrentState,
    getWaterFilterCurrentPressure,
    getWaterFilterConsumption,
    getWaterFilterMaxFlowRate,
    getWaterFilterPressure,
    getWaterFilterBypass,
    getTotalConsumptionWaterFilter,
    generateWaterFilterCsv,
    getWaterFilterCurrentBypass,
    getTotalConsumptionCartridgeWf,
    getAllTotalConsumptionCartridgeWf,
    replaceWaterFilterCartridges,
    getReverseOsmosisCurrentState,
    getReverseOsmosisConsumption,
    getTotalConsumptionReverseOsmosis,
    generateReverseOsmosisCsv,
    getReverseOsmosisTds,
    getReverseOsmosisWaste,
    getTotalConsumptionCartridgeRo,
    getAllTotalConsumptionCartridgeRo,
    replaceReverseOsmosisCartridges,
    getReverseOsmosisBypass,
    getReverseOsmosisCurrentBypass,
    getReverseOsmosisCurrentPressure,
    getReverseOsmosisCurrentTemperature,
    getSoftenerCurrentState,
    getSoftenerConsumption,
    getSoftenerPressure,
    getSoftenerBypass,
    getSoftenerWaste,
    generateSoftenerCsv,
    getTotalConsumptionSoftener,
    getSoftenerCurrentPressure,
    getSoftenerCurrentBypass,
    updateInstallationStatus,
    // Alerts
    getAlerts,
    getAllAlerts,
    getAlert,
    getAlertsTypes,
    getAlertType,
    createAlertType,
    editAlertType,
    deleteAlertType,
    markAlertAsViewed,
    // user
    getUsers,
    getUser,
    resendEmail,
    getInstallersByAccount,
    createUser,
    editUser,
    deleteUser,
    // account
    getAccounts,
    getAccount,
    getAccountsForFilter,
    createAccount,
    editAccount,
    deleteAccount,
    // brand
    getBrands,
    getBrand,
    getBrandsForFilter,
    createBrand,
    editBrand,
    deleteBrand,
    // Roles, States, and Cities
    getRoles,
    getStates,
    getCitiesByState,
    // Support
    getSupports,
    checkAsRead,
    deleteSupport,
    // Ota
    getOtas,
    getOta,
    getOtaVersions,
    createOtaVersion,
    deleteOtaVersion,
    getUpgradeStatus,
    getUpgradeStatusDevices,
    createUpgradeStatus,
    deleteUpgradeStatus,
    getPreviousInstallationInfo,
    getUpdateActiveDevices,
  };
};

export default {
  create,
};
